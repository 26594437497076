import React from "react";
import { ListItemIcon, ListItem, List, styled, ListItemText, useTheme, ListItemButton, Link } from "@mui/material";
// import { getUpdatedFields } from "utils/pwaHelpers";
import useCautionStore from "store/cautionStore";
import useSetupStore from "store/setupSettingsStore";
import { useAppStore } from "store/appStore";
import useIdStore from "store/pwaId";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useMessagesStore } from "store/userMessagesStore";
// import { Link } from "react-router-dom";
// import { usePathname, useRouter } from "next/navigation";

type NavGroup = {
  [x: string]: any;
  id?: string;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: any;
  onClick?: React.MouseEvent<HTMLButtonElement, MouseEvent>;
};

interface ItemType {
  item: NavGroup;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  hideMenu?: any;
  level?: number | any;
  pathDirect: string;
}

const NavItem = ({ item, level, pathDirect, onClick }: ItemType) => {
  const Icon = item.icon;
  const theme = useTheme();
  const itemIcon = <Icon stroke={1.5} size="1.3rem" />;
  const navigate = useNavigate();
  const location = useLocation();
  // const updatedFields = getUpdatedFields();
  const { openModal, handlePathChange } = useCautionStore();
  const setSettingProcess = useSetupStore((state: any) => state.setSettingProcess);
  const resetSteps = useSetupStore((state: any) => state.resetAllSteps);
  const { clearAppData } = useAppStore();
  const isPwaChanged = useAppStore((state) => state.isChanged);
  const { id, setLastId, clearId } = useIdStore();
  const { setMessageToShowId, changeMessagesCategory } = useMessagesStore();

  // const router = useRouter();
  // const currentPathName = usePathname();

  // useBeforeUnload(
  //   React.useCallback(() => {
  //     console.log("321");
  //     // localStorage.stuff = state;
  //   }, [])
  // );

  const ListItemStyled = styled(ListItem)(() => ({
    padding: 0,
    ".MuiButtonBase-root": {
      whiteSpace: "nowrap",
      marginBottom: "2px",
      padding: "8px 10px",
      borderRadius: "8px",
      backgroundColor: level > 1 ? "transparent !important" : "inherit",
      color: theme.palette.text.secondary,
      paddingLeft: "10px",
      "&:hover": {
        backgroundColor: "#F4701B",
        color: "white",
      },
      "&.Mui-selected": {
        color: "white",
        backgroundColor: "#F4701B",
        "&:hover": {
          color: "white",
          backgroundColor: "#F4701B",
        },
      },
    },
  }));

  const goTo = (path: string) => {
    const currentPathName = location.pathname;
    const isPWAPage = currentPathName.includes("edit-pwa") || currentPathName.endsWith("/pwa/create");
    if (isPwaChanged && isPWAPage) {
      openModal();
      handlePathChange(path);
    } else if (path === "/pwa") {
      clearAppData();
      setSettingProcess(false);
      resetSteps();
      setLastId(id);
      navigate(path);
    } else if (path === "/admin/messages") {
      changeMessagesCategory("all");
      setMessageToShowId("");
      navigate(path);
    } else {
      clearAppData();
      setSettingProcess(false);
      resetSteps();
      clearId();
      navigate(path);
      localStorage.removeItem("lastTabValue");
    }
  };

  return (
    <List component="div" disablePadding key={item.id}>
      <ListItemStyled
        onClick={() => {
          goTo(item.href);
        }}
      >
        <ListItemButton
          href={""}
          disabled={item.disabled}
          selected={pathDirect === item.href}
          target={item.external ? "_blank" : ""}
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              minWidth: "36px",
              p: "3px 0",
              color: "inherit",
            }}
          >
            {itemIcon}
          </ListItemIcon>
          <ListItemText>
            <>{item.title}</>
          </ListItemText>
        </ListItemButton>
      </ListItemStyled>
    </List>
  );
};

export default NavItem;

import { uniqueId } from "lodash";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import GroupIcon from "@mui/icons-material/Group";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DraftsIcon from "@mui/icons-material/Drafts";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import DomainIcon from "@mui/icons-material/Domain";
import SignpostIcon from "@mui/icons-material/Signpost";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuthStore } from "store/authStore";
import MessagesIcon from "./MessagesIcon";

const useMenuItems = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const location = useLocation();

  const isUserAdmin = user?.isAdmin;
  const isAdminPage = location.pathname.includes("admin");

  const menuItems = [
    {
      id: uniqueId(),
      title: t("dashboard"),
      icon: ViewComfyOutlinedIcon,
      href: "/dashboard",
    },
    {
      id: uniqueId(),
      title: "PWA",
      icon: PhoneIphoneOutlinedIcon,
      href: "/pwa",
    },
    {
      id: uniqueId(),
      title: t("statistics"),
      icon: AssessmentOutlinedIcon,
      href: "/stats",
    },
    {
      id: uniqueId(),
      title: "PUSH",
      icon: NotificationsOutlinedIcon,
      href: "/push",
    },
    {
      id: uniqueId(),
      title: "FAQ",
      icon: QuizOutlinedIcon,
      href: "/profile/faq",
    },
    {
      id: uniqueId(),
      title: t("support"),
      icon: SupportOutlinedIcon,
      href: "/support",
    },
  ];

  const adminMenuItems = [
    {
      id: uniqueId(),
      title: t("users"),
      icon: GroupIcon,
      href: "/admin/users",
    },
    {
      id: uniqueId(),
      title: t("promocodes"),
      icon: CardGiftcardIcon,
      href: "/admin/promocodes",
    },
    {
      id: uniqueId(),
      title: t("domains"),
      icon: DomainIcon,
      href: "/admin/domains",
    },
    {
      id: uniqueId(),
      title: t("trafficLinkName"),
      icon: SignpostIcon,
      href: "/admin/traffic",
    },
    {
      id: uniqueId(),
      title: t("messages"),
      icon: MessagesIcon,
      href: "/admin/messages",
    },
    {
      id: uniqueId(),
      title: t("tohomepage"),
      icon: HomeIcon,
      href: "/dashboard",
    },
  ];

  return isAdminPage && isUserAdmin ? adminMenuItems : menuItems;
};

export default useMenuItems;

import { emptyPushData } from "store/pushStore";

interface BackendData {
  awaitFeedback: boolean;
  body: string;
  created_at: string;
  days_and_times: { day_of_week: string; time: string; _id: string }[];
  schedule_date_timestamp: number;
  delivery_schedule_status: string;
  geo_filter: string[];
  icon: string;
  name: string;
  pwa_id: string;
  repeatNotify: boolean;
  silentMode: boolean;
  status: string;
  timezone_aware: boolean;
  title: string;
  url: string;
  user_id: string;
  __v: number;
  _id: string;
}

/**
 * Transforms backend data into PushDataMockup format used in Zustand store.
 * @param backendData The data fetched from the backend.
 * @return The transformed PushDataMockup object.
 */
export function transformBackendDataToPushData(backendData: BackendData) {
  return {
    ...emptyPushData,
    pushName: backendData.name,
    schedule_date_timestamp: backendData.schedule_date_timestamp,
    useUserTimezone: backendData.timezone_aware,
    intervalDates: backendData.days_and_times.reduce(
      (acc, schedule) => {
        acc[schedule.day_of_week.toLowerCase()] = schedule.time;
        return acc;
      },
      {} as Record<string, string>
    ),
    headings: backendData.title,
    contents: backendData.body,
    notifyAgain: backendData.repeatNotify,
    awaitResponse: backendData.awaitFeedback,
    silentMode: backendData.silentMode,
    geo: backendData.geo_filter,
    pwaId: backendData.pwa_id,
    icon: backendData.icon,
    origin: backendData.url,
    delivery: backendData.delivery_schedule_status,
    url: backendData.url,
  };
}

import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PushTable from "components/PushTable";
import { useNavigate } from "react-router";
import StatisticPushWidget from "components/StatisticPushWidget";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const Pushes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function createPush() {
    navigate("/push/create");
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontSize: "24px" }}>
            {t("pushNotifications")}
          </Typography>
          <Box>
            <Button
              sx={{
                color: "#fff",
                backgroundColor: "#F4701B",
                textTransform: "uppercase",
                "&:hover": {
                  backgroundColor: "#F0670B",
                },
              }}
              className="create_btn"
              variant="contained"
              aria-label="create pwa"
              size="medium"
              onClick={createPush}
            >
              {t("createPush")}
            </Button>
          </Box>
        </Grid>

        <Box sx={{ display: { xs: "none", sm: "flex", width: "100%", padding: "16px" } }}>
          <StatisticPushWidget />
        </Box>
        <Grid item xs={12} lg={12}>
          <PushTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pushes;

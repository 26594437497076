function getCenterCoordinates(coordinates: any): { latitude: number; longitude: number } {
  const flattenCoordinates = (coords: any): number[][] => {
    return coords.flatMap((coord: any) => (Array.isArray(coord[0]) ? flattenCoordinates(coord) : [coord]));
  };

  const allCoordinates = flattenCoordinates(coordinates);
  const total = allCoordinates.reduce(
    (acc, [lon, lat]) => ({ longitude: acc.longitude + lon, latitude: acc.latitude + lat }),
    { longitude: 0, latitude: 0 }
  );
  const count = allCoordinates.length;

  return {
    latitude: total.latitude / count,
    longitude: total.longitude / count,
  };
}

export function getCountryWithCenterData(statsData: any, worldMap: any, countries: any) {
  const statsCountriesCodes = Object.keys(statsData);
  return statsCountriesCodes
    .map((code) => {
      const country = countries.find((country: any) => country.code === code);

      if (country === undefined) {
        return {
          country: null,
          latitude: null,
          longitude: null,
          coordinates: null,
        };
      }

      const coordinates = worldMap.features.find((feature: any) => feature.properties.admin === country.name);

      if (coordinates) {
        const center = getCenterCoordinates(coordinates.geometry.coordinates);
        return {
          country: country.name,
          latitude: center.latitude,
          longitude: center.longitude,
          coordinates: coordinates.geometry.coordinates,
        };
      } else {
        return {
          country: country.name,
          latitude: null,
          longitude: null,
          coordinates: null,
        };
      }
    })
    .filter((country: any) => country.coordinates !== null);
}

import { Box, styled, Typography, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Calendar from "assets/icons/Calendar";
import SwitchComponent from "components/Checkbox";
import TimePicker from "components/TimePicker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { usePushStore } from "store/pushStore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TimezoneSwitch from "components/TimeZoneCheckbox";

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  width: "100%",

  "& .MuiIconButton-root": {
    paddingRight: "15px",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#F6F7FB",
    borderRadius: 8,
    width: "100%",
    height: 40,
    marginRight: "8px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
    height: "40px",
    fontWeight: "400",
    fontFamily: "var(--cygreMedium), sans-serif",
  },
}));

interface IStandartSets {
  scheduledTimestamp?: number;
  useUserTimezone?: boolean;
}

export default function StandartSets({ scheduledTimestamp, useUserTimezone }: IStandartSets) {
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
  const savePushData = usePushStore((state) => state.savePushData);

  useEffect(() => {
    if (scheduledTimestamp) {
      setSelectedTime(dayjs(scheduledTimestamp));
    }
  }, [scheduledTimestamp]);

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedTime(newValue);
    if (newValue) {
      const standartDate = newValue.format();

      // @ts-ignore
      savePushData({ standartDate });
      savePushData({ intervalDates: {} });
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        <Box sx={{ width: { xs: "100%", md: 172 } }}>
          <CustomDatePicker
            slots={{
              openPickerIcon: Calendar,
            }}
            value={selectedTime}
            onChange={(newValue) => handleDateChange(newValue)}
            slotProps={{
              textField: {
                inputProps: { disabled: true },
              },
            }}
            minDate={dayjs()}
          />
        </Box>

        <Box
          sx={{ paddingTop: { xs: "8px", md: 0 }, paddingLeft: { xs: 0, md: "8px" }, width: { xs: "100%", md: 172 } }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker value={selectedTime} />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", paddingTop: "10px" }}>
        <TimezoneSwitch />
        <Typography sx={{ paddingLeft: "12px" }} variant="h6">
          {t("useUserTimezone")}
        </Typography>
      </Box>
    </Box>
  );
}

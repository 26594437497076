import { create } from "zustand";
import { IUserMessage } from "interfaces/userMessages";

interface IMessagesStore {
  userMessagesArr: IUserMessage[] | [];
  messagesCategory: string;
  messageToShowId: string;
  saveUserMessages: (messagesArr: IUserMessage[]) => void;
  changeMessagesCategory: (category: string) => void;
  setMessageToShowId: (id: string) => void;
}

const useMessagesStore = create<IMessagesStore>((set) => ({
  userMessagesArr: [],
  messagesCategory: "all",
  messageToShowId: "",
  saveUserMessages: (messagesArr) => set({ userMessagesArr: messagesArr }),
  changeMessagesCategory: (category) => set({ messagesCategory: category }),
  setMessageToShowId: (id) => set({ messageToShowId: id }),
}));

export { useMessagesStore };

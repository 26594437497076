import React, { useState, useEffect, useRef, FormEvent } from "react";
import { Box, Typography } from "@mui/material";
import CustomInput from "components/Input";
import CustomButton from "components/Button";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { saveToken, validateForm } from "utils";
import Email from "assets/icons/Email";
import Lock from "assets/icons/Lock";
import ShowFalse from "assets/icons/ShowFalse";
import ShowTrue from "assets/icons/ShowYes";
import ToastMessage from "components/ToastMessage";
import imgSrc from "assets/imageAuth.png";
import GoogleIcon from "assets/icons/GoogleIcon";
import { ButtonTelegramAuth } from "./TelegramButton";
import { useNavigate } from "react-router";
import globalLoaderStore from "store/globalLoaderStore";

interface LoginProps {
  onClose: (value: boolean) => void;
  toggleMode: (newMode: string) => void;
}

export function Login({ onClose, toggleMode }: LoginProps) {
  const setIsLoading = globalLoaderStore((state) => state.setIsGlobalLoading);
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const referralCode = searchparams.get("ref");
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formDataError, setFormDataError] = useState({ email: "", password: "" });
  const [showPsw, setShowPsw] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPsw(!showPsw);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { loading, triggerFetch } = useFetch({
    url: "auth/login",
    method: "POST",
    body: formData,
    context: { no401Redirect: true },
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const errors = validateForm(formData);
    if (errors.email || errors.password) {
      setFormDataError(errors);
      return;
    }
    setIsLoading(true);
    const result = await triggerFetch();
    if (result && result.success) {
      const token = result.data.token;
      saveToken(token);
      navigate("/dashboard");
      onClose(false);
      setIsLoading(false);
    } else {
      ToastMessage({ mode: "error", message: result?.message });
      setIsLoading(false);
    }
  };

  const handleClickGoogle = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}auth/google${referralCode ? `?referralCode=${referralCode}` : ""}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row-reverse" },
        alignItems: "center",
        textAlign: { xs: "center", md: "left" },
        width: { xs: "100%", md: 720 },
        maxWidth: { xs: "auto", md: "720px" },
        margin: "auto",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", paddingLeft: "20px", mb: { xs: 2, md: 0 } }}>
        <img src={imgSrc} alt="some image" style={{ maxWidth: "250px", height: "auto" }} />
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ flex: 1, width: { xs: "100%", md: "auto" } }}>
        <Typography
          variant="subtitle1"
          style={{
            textAlign: "center",
          }}
        >
          {t("welcome")}
        </Typography>
        <Typography variant="subtitle2">{t("wearehappytohaveyoubackwithus")}</Typography>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="email"
          customFontSize="18px"
          placeholder={t("email")}
          name="email"
          height={"57px"}
          autoComplete="email"
          // autoFocus
          value={formData.email}
          onChange={handleInputChange}
          error={!!formDataError.email}
          helperText={formDataError.email}
          iconStart={<Email />}
          customBgColor="#F6F7F9"
        />
        <CustomInput
          margin="normal"
          required
          height={"57px"}
          fullWidth
          customFontSize="18px"
          id="password"
          placeholder={t("password")}
          name="password"
          type={showPsw ? "text" : "password"}
          autoComplete="current-password"
          value={formData.password}
          onChange={handleInputChange}
          error={!!formDataError.password}
          helperText={formDataError.password}
          iconStart={<Lock color="#000" width={19} height={20} />}
          iconEnd={
            <Box sx={{ cursor: "pointer", paddingTop: "10px" }} onClick={togglePasswordVisibility}>
              {showPsw ? <ShowTrue /> : <ShowFalse />}
            </Box>
          }
          customBgColor="#F6F7F9"
        />
        <Box
          onClick={() => toggleMode("forgotPassword")}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Typography sx={{ fontSize: "14px", color: "#000" }}>{t("forgotyourpassword")}</Typography>
        </Box>
        <Box sx={{ paddingTop: "15px" }}>
          <CustomButton
            loading={loading}
            height={57}
            width="100%"
            loginDisabled={loading}
            textButton={t("login")}
            customBgColor="#000"
            customHoverColor="#F4701B"
            customFontSize={"18px"}
            lineHeight={2}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <ButtonTelegramAuth />
          <Typography sx={{ color: "#000", padding: "0 5px", textTransform: "uppercase" }}>{t("or")}</Typography>
          <Box
            sx={{
              cursor: "pointer",
              border: "1px solid #000",
              display: "flex",
              alignItems: "center",
              borderRadius: "20px",
              padding: "3px 10px",
              marginTop: "10px",
            }}
            onClick={handleClickGoogle}
          >
            <GoogleIcon />
            <Typography
              color={"#000"}
              style={{
                fontSize: "16px",
                fontFamily: "var(--cygreMedium), sans-serif",
                marginLeft: "5px",
              }}
            >
              {t("signinwithgoogle")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}></Box>
        </Box>
        <Box sx={{ paddingTop: "10px", cursor: "pointer" }} onClick={() => toggleMode("signup")}>
          <Typography variant="subtitle2">{t("noaccountsignup")}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

import React from "react";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import useCautionStore from "store/cautionStore";
import { useMessagesStore } from "store/userMessagesStore";
import { IUserMessage } from "interfaces/userMessages";
import { useTranslation } from "react-i18next";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

interface IMessageDetailsProps {
  messagesArr: IUserMessage[];
}

const MessagesList = ({ messagesArr }: IMessageDetailsProps) => {
  const { t } = useTranslation();
  const { setMessageToShowId, messagesCategory, changeMessagesCategory } = useMessagesStore();
  const { handleMessageToDeleteChange, openMessageDeleteModal } = useCautionStore();

  const handleMessageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { id } = event.currentTarget;
    setMessageToShowId(id);
    changeMessagesCategory("message-details");
  };

  const handleMessageDelete = (id: string) => {
    openMessageDeleteModal();
    handleMessageToDeleteChange(id);
  };

  return !messagesArr || messagesArr.length === 0 ? (
    <Box>
      <Typography variant="subtitle2" fontWeight={400} color="#2E263D" fontSize={14}>
        {t("noitems")}
      </Typography>
    </Box>
  ) : (
    <List
      dense
      sx={{
        "&.MuiList-root": { display: "flex", flexDirection: "column", gap: "6px" },
      }}
    >
      {messagesArr.map((message) => {
        const { _id, icon, userEmail, messageText } = message;
        const isMessageRead = message.read.isRead;
        const isMessageProcessed = message.processed.isProcessed;
        const upperCaseUserEmail = userEmail.toUpperCase();
        const formattedMessageDate = moment(message?.createdAt).format("DD.MM.YYYY");

        const isMessageReadOrProcessed =
          (messagesCategory === "all" && isMessageRead) ||
          (messagesCategory === "unread" && isMessageRead) ||
          (messagesCategory === "pending" && isMessageProcessed);

        return (
          <ListItem
            key={_id}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="comments"
                onClick={() => {
                  handleMessageDelete(_id);
                }}
              >
                <BootstrapTooltip title={t("delete")} placement="top">
                  <DeleteIcon sx={{ width: "18px", height: "18px", color: "#6D6777" }} />
                </BootstrapTooltip>
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton
              id={_id}
              sx={{ padding: "4px 0px", boxShadow: "inset 0 -1px 0 0 rgba(100, 121, 143, 0.12)" }}
              onClick={(event) => handleMessageClick(event)}
            >
              <ListItemAvatar>
                <Avatar alt={upperCaseUserEmail} src={`${icon}`} sx={{ width: "36px", height: "36px" }} />
              </ListItemAvatar>
              <ListItemText
                id={_id}
                primary={messageText}
                sx={{
                  "& .MuiTypography-root": {
                    paddingRight: "20px",
                    fontWeight: isMessageReadOrProcessed ? 300 : 500,
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              />
              <Typography sx={{ fontSize: "12px", fontWeight: isMessageReadOrProcessed ? 300 : 500 }}>
                {formattedMessageDate}
              </Typography>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default MessagesList;

import SwitchComponent from "components/Checkbox";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TimePicker from "components/TimePicker";
import { useState, useEffect } from "react";
import { usePushStore } from "store/pushStore";
import dayjs, { Dayjs } from "dayjs";
import TimezoneSwitch from "../TimeZoneCheckbox";

export type DayKey = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

interface IIntervalSets {
  intervalDates?: Record<DayKey, string | null>;
}

export default function IntervalSets({ intervalDates }: IIntervalSets) {
  const { t } = useTranslation();
  const savePushData = usePushStore((state) => state.savePushData);
  const pushData = usePushStore((state) => state.pushData);
  const [switchStates, setSwitchStates] = useState<Record<DayKey, boolean>>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [timeStates, setTimeStates] = useState<Record<DayKey, Dayjs | null>>({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  });

  useEffect(() => {
    if (intervalDates) {
      setSwitchStates((prevState) => {
        const updatedState: Record<DayKey, boolean> = { ...prevState };
        for (const dayKey in intervalDates) {
          if (intervalDates.hasOwnProperty(dayKey)) {
            updatedState[dayKey as DayKey] = intervalDates[dayKey as DayKey] !== null;
          }
        }
        return updatedState;
      });

      setTimeStates((prevTimes: Record<DayKey, Dayjs | null>) => {
        const updatedTimes: Record<DayKey, Dayjs | null> = { ...prevTimes };

        Object.keys(intervalDates).forEach((key) => {
          const dayKey = key as DayKey;
          const timeString = intervalDates[dayKey];

          if (timeString && dayjs(timeString, "HH:mm", true).isValid()) {
            updatedTimes[dayKey] = dayjs(timeString, "HH:mm");
          } else {
            updatedTimes[dayKey] = null; // Handle invalid or null values
          }
        });

        return updatedTimes;
      });
    }
  }, [intervalDates]);

  const handleSwitchChange = (dayKey: DayKey, checked: boolean) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [dayKey]: checked,
    }));

    if (checked) {
      savePushData({
        intervalDates: {
          ...pushData.intervalDates,
          [dayKey]: timeStates[dayKey] ? timeStates[dayKey]!.format("HH:mm") : null,
        },
        standartDate: "",
        standartTime: "",
      });
    } else {
      savePushData({
        intervalDates: {
          ...pushData.intervalDates,
          [dayKey]: null,
        },
      });
    }
  };

  const handleTimeChange = (dayKey: DayKey, newValue: Dayjs | null) => {
    setTimeStates((prevTimes) => ({
      ...prevTimes,
      [dayKey]: newValue,
    }));

    if (newValue) {
      const formattedTime = newValue.format("HH:mm");

      savePushData({
        intervalDates: {
          ...pushData.intervalDates,
          [dayKey]: formattedTime,
        },
      });
    }
  };

  const daysOfWeek = [
    { label: t("monday"), key: "monday" },
    { label: t("tuesday"), key: "tuesday" },
    { label: t("wednesday"), key: "wednesday" },
    { label: t("thursday"), key: "thursday" },
    { label: t("friday"), key: "friday" },
    { label: t("saturday"), key: "saturday" },
    { label: t("sunday"), key: "sunday" },
  ];

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", paddingTop: "10px" }}>
        <TimezoneSwitch />
        <Typography sx={{ paddingLeft: "12px" }} variant="h6">
          {t("useUserTimezone")}
        </Typography>
      </Box>
      <hr />
      {daysOfWeek.map(({ label, key }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "334px",
            padding: "8px 0",
          }}
          key={key}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", height: "40px" }}>
            <SwitchComponent
              checked={switchStates[key as DayKey]}
              onChange={(e, checked) => handleSwitchChange(key as DayKey, checked)}
            />
            <Typography sx={{ padding: "0 12px 0 16px" }} variant="h6">
              {label}
            </Typography>
          </Box>
          {switchStates[key as DayKey] && (
            <Box sx={{ width: "176px" }}>
              <TimePicker
                value={timeStates[key as DayKey]}
                onChange={(newValue) => handleTimeChange(key as DayKey, newValue)}
              />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}

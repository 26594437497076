import React, { useState } from "react";

export const LazyImage = React.memo(
  ({ src, placeholder, alt, customStyles }: { src: string; placeholder: string; alt: string; customStyles: any }) => {
    const [loaded, setLoaded] = useState(false);

    return (
      <img
        src={loaded ? src : placeholder}
        alt={alt}
        onLoad={() => setLoaded(true)} // Switch to actual image on load
        style={{
          filter: loaded ? "none" : "blur(20px)",
          transition: "filter 0.5s ease",
          ...customStyles,
        }}
        loading="lazy"
      />
    );
  }
);

import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useTranslation } from "react-i18next";
import FilterCharts from "./FilterCharts";
import SelectPeriod from "./SelectPeriod";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import Preloader from "components/GlobalPreloader/Preloader";
import { ITransaction } from "interfaces/stats";
import i18n from "i18n";
import {
  formatDateByLocaleDDMM,
  formatMMDDYYYY,
  generateDaysOfMonth,
  generateHoursOfDay,
  getDaysInMonth,
  getLocaleFormat,
} from "utils/formateDate";

interface DataPoint {
  label: string;
  color: string;
  data: any;
  id: string;
  stack: string | undefined;
}

interface ResultItem {
  value: number;
  createdAt: Date;
}

interface IChartsProps {
  usersCampaingIds: number[];
  installs: ITransaction[];
  pwaIds: string[];
}

interface IStoreStats {
  keyword: string;
  datetime: string;
  clicks: number;
}

function MyBarChart({ pwaIds, usersCampaingIds, installs }: IChartsProps) {
  const token = getToken();
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<string>("all");
  const [selectedPeriod, setSelectedPeriod] = useState<string>("1_month_ago");
  const [customPeriod, setCustomPeriod] = useState<[string | null, string | null]>([null, null]);
  const [reportData, setReportData] = useState([]);
  const [storeStats, setStoreStats] = useState<Array<IStoreStats>>([]);
  const [chartData, setChartData] = useState<DataPoint[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const date = new Date();
  const dateToday = formatMMDDYYYY(date);
  const firstDayOfMonth = formatMMDDYYYY(date, true);
  const fromDate = customPeriod[0];
  const toDate = customPeriod[1] || customPeriod[0];

  const { triggerFetch: getReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: selectedPeriod === "1_month_ago" ? firstDayOfMonth : fromDate,
          to: selectedPeriod === "1_month_ago" ? dateToday : toDate,
          timezone: "Europe/Madrid",
          interval: null,
        },
        metrics: ["campaign_unique_clicks", "leads", "sales", "datetime"],
        filters: [
          {
            name: "campaign_id",
            operator: "IN_LIST",
            expression: usersCampaingIds,
          },
        ],
      },
    },
  });

  const { triggerFetch: getStoreReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          from: selectedPeriod === "1_month_ago" ? firstDayOfMonth : fromDate,
          to: selectedPeriod === "1_month_ago" ? dateToday : toDate,
          timezone: "Europe/Madrid",
          interval: null,
        },
        dimensions: ["keyword"],
        metrics: ["clicks", "datetime"],
        filters: [
          {
            name: "keyword",
            operator: "IN_LIST",
            expression: pwaIds,
          },
        ],
        sort: [
          {
            name: "clicks",
            order: "DESC",
          },
        ],
      },
    },
  });

  const generateCustomPeriod = (customPeriod: [string | null, string | null]): string[] => {
    const [start, end] = customPeriod;

    if (!start && !end) return [];

    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;

    if (startDate && !endDate) {
      return generateHoursOfDay();
    }

    if (start === end) {
      return generateHoursOfDay();
    }

    if (startDate && endDate) {
      const dayDifference = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

      if (dayDifference <= 31) {
        const result: string[] = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          result.push(formatDateByLocaleDDMM(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return result;
      }
      const result: string[] = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const currentLanguage = getLocaleFormat(i18n.language);
        const monthName = currentDate.toLocaleString(currentLanguage, { month: "short" });
        const year = currentDate.getFullYear();
        result.push(`${monthName} ${year}`);
        currentDate.setMonth(currentDate.getMonth() + 1);

        if (
          currentDate > endDate &&
          currentDate.getMonth() === endDate.getMonth() &&
          currentDate.getFullYear() === endDate.getFullYear()
        ) {
          const lastMonthName = endDate.toLocaleString(currentLanguage, { month: "short" });
          const lastYear = endDate.getFullYear();
          result.push(`${lastMonthName} ${lastYear}`);
        }
      }

      return result;
    }

    return [];
  };

  const getXAxisData = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    switch (selectedPeriod) {
      case "1_month_ago":
        return generateDaysOfMonth(year, month);
      case "custom":
        return generateCustomPeriod(customPeriod);
      default:
        return [];
    }
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setCustomPeriod([formatMMDDYYYY(dates[0]), formatMMDDYYYY(dates[1])]);
  };

  const getDaysBetweenDates = (startDate: string | null, endDate: string | null): number | null => {
    if (!startDate || !endDate) {
      return null;
    }

    const start: Date = new Date(startDate);
    const end: Date = new Date(endDate);

    const difference: number = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
    return difference;
  };

  const formatInstalls = (installs: ITransaction[]) => {
    const resultArray: ResultItem[] = installs?.map(({ createdAt }) => ({
      value: 1,
      createdAt,
    }));

    return resultArray;
  };

  const formatChartData = (rows: any[], storeRows: IStoreStats[], installsArr: ITransaction[]) => {
    let uniques: number[] = [];
    let installs: number[] = [];
    let clicks: number[] = [];
    let regs: number[] = [];
    let deps: number[] = [];

    const totalDaysInMonth = getDaysInMonth();
    const daysInRange = getDaysBetweenDates(customPeriod[0], customPeriod[1]);
    const isMonthsStats = daysInRange && daysInRange >= 31;
    const transaction = formatInstalls(installsArr);

    if (selectedPeriod === "1_month_ago") {
      uniques = new Array(totalDaysInMonth).fill(0);
      clicks = new Array(totalDaysInMonth).fill(0);
      regs = new Array(totalDaysInMonth).fill(0);
      deps = new Array(totalDaysInMonth).fill(0);
      installs = new Array(totalDaysInMonth).fill(0);

      rows.forEach((row) => {
        const date = new Date(row.datetime);
        const day = date.getDate();

        uniques[day - 1] += row.campaign_unique_clicks || 0;
        regs[day - 1] += row.leads || 0;
        deps[day - 1] += row.sales || 0;
      });

      storeRows.forEach((row) => {
        const date = new Date(row.datetime);
        const day = date.getDate();

        clicks[day - 1] += row.clicks || 0;
      });

      transaction.forEach((row) => {
        const date = new Date(row.createdAt);
        const day = date.getDate();
        const transactionMonth = date.getMonth();
        const transactionYear = date.getFullYear();
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        if (transactionMonth === currentMonth && transactionYear === currentYear) {
          installs[day - 1] += row.value || 0;
        }
      });
    }

    if ((customPeriod[0] && !customPeriod[1]) || (customPeriod[0] && customPeriod[0] === customPeriod[1])) {
      uniques = new Array(24).fill(0);
      installs = new Array(24).fill(0);
      clicks = new Array(24).fill(0);
      regs = new Array(24).fill(0);
      deps = new Array(24).fill(0);

      const selectedDate = new Date(customPeriod[0]);
      selectedDate.setHours(0, 0, 0, 0);

      rows.forEach((row) => {
        const rowDate = new Date(row.datetime);
        if (
          rowDate.getFullYear() === selectedDate.getFullYear() &&
          rowDate.getMonth() === selectedDate.getMonth() &&
          rowDate.getDate() === selectedDate.getDate()
        ) {
          const hour = rowDate.getHours();
          uniques[hour] += row.campaign_unique_clicks || 0;
          regs[hour] += row.leads || 0;
          deps[hour] += row.sales || 0;
        }
      });

      storeRows.forEach((row) => {
        const rowDate = new Date(row.datetime);

        if (
          rowDate.getFullYear() === selectedDate.getFullYear() &&
          rowDate.getMonth() === selectedDate.getMonth() &&
          rowDate.getDate() === selectedDate.getDate()
        ) {
          const hour = rowDate.getHours();
          clicks[hour] += row.clicks || 0;
        }
      });

      transaction.forEach((row) => {
        const rowDate = new Date(row.createdAt);
        if (
          rowDate.getFullYear() === selectedDate.getFullYear() &&
          rowDate.getMonth() === selectedDate.getMonth() &&
          rowDate.getDate() === selectedDate.getDate()
        ) {
          const hour = rowDate.getHours();
          installs[hour] += row.value || 0;
        }
      });
    }

    if (customPeriod[0] && customPeriod[1] && customPeriod[0] !== customPeriod[1] && daysInRange && daysInRange <= 31) {
      const startDate = new Date(customPeriod[0]);
      const endDate = new Date(customPeriod[1]);

      const startDateForComparison = new Date(startDate);
      startDateForComparison.setHours(0, 0, 0, 0);

      const endDateForComparison = new Date(endDate);
      endDateForComparison.setHours(23, 59, 59, 999);

      const totalDays =
        Math.ceil((endDateForComparison.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24)) + 1;

      uniques = new Array(totalDays).fill(0);
      clicks = new Array(totalDays).fill(0);
      regs = new Array(totalDays).fill(0);
      deps = new Array(totalDays).fill(0);
      installs = new Array(totalDays).fill(0);

      rows.forEach((row) => {
        const rowDate = new Date(row.datetime);
        if (rowDate >= startDateForComparison && rowDate <= endDateForComparison) {
          const dayIndex = Math.floor((rowDate.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24));
          uniques[dayIndex] += row.campaign_unique_clicks || 0;
          regs[dayIndex] += row.leads || 0;
          deps[dayIndex] += row.sales || 0;
        }
      });

      storeRows.forEach((row) => {
        const rowDate = new Date(row.datetime);
        if (rowDate >= startDateForComparison && rowDate <= endDateForComparison) {
          const dayIndex = Math.floor((rowDate.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24));
          clicks[dayIndex] += row.clicks || 0;
        }
      });

      transaction.forEach((row) => {
        const rowDate = new Date(row.createdAt);
        if (rowDate >= startDateForComparison && rowDate <= endDateForComparison) {
          const dayIndex = Math.floor((rowDate.getTime() - startDateForComparison.getTime()) / (1000 * 3600 * 24));
          installs[dayIndex] += row.value || 0;
        }
      });
    }

    if (customPeriod[0] && customPeriod[1] && customPeriod[0] !== customPeriod[1] && daysInRange && daysInRange > 31) {
      const startDate = new Date(customPeriod[0]);
      const endDate = new Date(customPeriod[1]);

      const months: string[] = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const monthName = currentDate.toLocaleString("en-US", { month: "short" });
        const year = currentDate.getFullYear();
        months.push(`${monthName} ${year}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      uniques = new Array(months.length).fill(0);
      installs = new Array(months.length).fill(0);
      clicks = new Array(months.length).fill(0);
      regs = new Array(months.length).fill(0);
      deps = new Array(months.length).fill(0);

      rows.forEach((row) => {
        const rowDate = new Date(row.datetime);
        months.forEach((month, index) => {
          const [monthName, year] = month.split(" ");
          const rowMonth = rowDate.toLocaleString("en-US", { month: "short" });
          const rowYear = rowDate.getFullYear().toString();

          if (rowMonth === monthName && rowYear === year) {
            uniques[index] += row.campaign_unique_clicks || 0;
            regs[index] += row.leads || 0;
            deps[index] += row.sales || 0;
          }
        });
      });

      storeRows.forEach((row) => {
        const rowDate = new Date(row.datetime);
        months.forEach((month, index) => {
          const [monthName, year] = month.split(" ");
          const rowMonth = rowDate.toLocaleString("en-US", { month: "short" });
          const rowYear = rowDate.getFullYear().toString();

          if (rowMonth === monthName && rowYear === year) {
            clicks[index] += row.clicks;
          }
        });
      });

      transaction.forEach((row) => {
        const rowDate = new Date(row.createdAt);
        months.forEach((month, index) => {
          const [monthName, year] = month.split(" ");
          const rowMonth = rowDate.toLocaleString("en-US", { month: "short" });
          const rowYear = rowDate.getFullYear().toString();

          if (rowMonth === monthName && rowYear === year) {
            installs[index] += row.value || 0;
          }
        });
      });
    }

    return [
      {
        id: "uniques",
        label: t("uniques"),
        color: "#8C57FF",
        data: uniques,
        stack: !isMonthsStats ? "total" : undefined,
      },
      {
        id: "installs",
        label: t("installs"),
        color: "#16B1FF",
        data: installs,
        stack: !isMonthsStats ? "total" : undefined,
      },
      {
        id: "clicks",
        label: t("clicks"),
        color: "#56CA00",
        data: clicks,
        stack: !isMonthsStats ? "total" : undefined,
      },
      {
        id: "regs",
        label: t("regs"),
        color: "#F4701B",
        data: regs,
        stack: !isMonthsStats ? "total" : undefined,
      },
      {
        id: "deps",
        label: t("deps"),
        color: "#f1da06",
        data: deps,
        stack: !isMonthsStats ? "total" : undefined,
      },
    ];
  };

  const checkFormatedData = (data: DataPoint[]): DataPoint[] => {
    const hasNonZeroValue = data.some((item) => item.data.some((value: number) => value !== 0));

    if (hasNonZeroValue) {
      return data;
    }
    return data.map((item) => ({
      ...item,
      values: [],
    }));
  };

  async function getDataReport(signal: AbortSignal) {
    setChartData([]);
    const res = await getReport(null, undefined, { signal });
    const storeReport = await getStoreReport(null, undefined, { signal });

    const rows = res?.data?.rows || [];
    const storeRows = storeReport?.data?.rows || [];
    setReportData(rows);
    setStoreStats(storeRows);
  }

  useEffect(() => {
    if (usersCampaingIds.length === 0) {
      return;
    }

    let isRequestCancelled = false;
    setIsLoading(true);

    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      try {
        setIsLoading(true);
        await getDataReport(signal);

        if (!isRequestCancelled) {
          setIsLoading(false);
        }
      } catch (error) {
        if (!isRequestCancelled) {
          setIsLoading(false);
        }
      }
    })();

    return () => {
      isRequestCancelled = true;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersCampaingIds, selectedPeriod, customPeriod]);

  useEffect(() => {
    if (reportData.length === 0 && storeStats.length === 0) {
      return;
    }

    const formattedData = checkFormatedData(formatChartData(reportData, storeStats, installs));
    setChartData(formattedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, installs]);

  const filteredData = selectedFilter === "all" ? chartData : chartData.filter((item) => item.id === selectedFilter);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          padding: { xs: "0 27px", lg: "0" },
        }}
      >
        <Typography
          sx={{
            fontFamily: "var(--cygreMedium), sans-serif",
            color: "#2E263D",
            fontSize: "16px",
            lineHeight: "32px",
          }}
        >
          {t("analytics")}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", padding: { xs: "15px 0", lg: "0" } }}>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#8C57FF",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("uniques")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#16B1FF",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("installs")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#56CA00",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("clicks")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#F4701B",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("regs")}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#f1da06",
                marginRight: "12px",
              }}
            />
            <Typography sx={{ fontSize: "14px" }}>{t("deps")}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
          <Box sx={{ paddingRight: "12px" }}>
            <FilterCharts selectedFilter={selectedFilter} onChange={setSelectedFilter} />
          </Box>
          <SelectPeriod
            setError={setError}
            error={error}
            selectedPeriod={selectedPeriod}
            onChange={setSelectedPeriod}
            onChangeDateRange={handleDateChange}
            isBarChartSelect={true}
          />
        </Box>
      </Box>

      <Box
        sx={{
          overflowY: "hidden",
          "& .MuiChartsGrid-line": {
            stroke: "#F0F0F0",
            strokeDasharray: "4 4",
          },
          "& .MuiChartsAxis-tick": {
            stroke: "#d4d4d4 !important",
          },
        }}
      >
        <Box
          position="relative"
          sx={{
            minWidth: { xs: "100%" },
          }}
        >
          {isLoading && (
            <Preloader
              customStyles={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
                background: "rgba(255, 255, 255, 0.8)",
                zIndex: 20,
              }}
            />
          )}
          <BarChart
            grid={{ vertical: true, horizontal: true }}
            series={filteredData.map(({ label, color, data, stack }) => ({
              label,
              data,
              color,
              stack,
              stackOrder: "descending",
            }))}
            xAxis={[
              {
                id: "time",
                data: getXAxisData(),
                scaleType: "band",
                tickFontSize: 10,
                categoryGapRatio: 0.65,
                barGapRatio: 0.25,
                tickSize: 5,
                tickPlacement: "extremities",
              } as any,
            ]}
            yAxis={[
              {
                id: "events",
                min: 0,
                max: filteredData.length === 0 ? 100 : undefined,
                tickFontSize: 10,
                tickSize: 5,
              },
            ]}
            slotProps={{
              legend: {
                hidden: true,
              },
            }}
            height={400}
            borderRadius={3}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default MyBarChart;

import { Avatar, Box, Checkbox, Link, Typography } from "@mui/material";
import useFetch from "hooks/useFetch";
import useIsTablet from "hooks/useIsTablet";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMessagesStore } from "store/userMessagesStore";
import { getToken } from "utils/getToken";
import TelegramIcon from "@mui/icons-material/Telegram";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import GmailIcon from "assets/icons/GmailIcon";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { toast } from "react-toastify";

const MessageDetails = () => {
  const token = getToken();
  const { messageToShowId, userMessagesArr, saveUserMessages } = useMessagesStore();
  const isTablet = useIsTablet();
  const { t } = useTranslation();

  const currentUserMessage = userMessagesArr.find((message) => {
    return message._id === messageToShowId;
  });

  const isMessageReadByAdmin = currentUserMessage?.read.isRead;
  const isMessageProcessedByAdmin = currentUserMessage?.processed.isProcessed;
  const formattedMessageDate = moment(currentUserMessage?.createdAt).format("DD.MM.YYYY (HH:mm)");
  const userTelegram = currentUserMessage?.userTelegram;
  const userEmail = currentUserMessage?.userEmail;

  const { triggerFetch: updateUserMessageStatus } = useFetch({
    url: `admin/messages/${messageToShowId}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleMessageReadStatusChange = async () => {
    const userMessageWithNewStatus = await updateUserMessageStatus({ editStatusField: "read" });

    if (userMessageWithNewStatus?.success) {
      const updatedUserMessagesArr = userMessagesArr.map((message) => {
        if (message._id === userMessageWithNewStatus.message._id) {
          return userMessageWithNewStatus.message;
        } else {
          return message;
        }
      });
      saveUserMessages(updatedUserMessagesArr);
    }
  };

  const handleMessageProcessedStatusChange = async () => {
    try {
      const userMessageWithNewStatus = await updateUserMessageStatus({ editStatusField: "processed" });

      if (userMessageWithNewStatus?.success) {
        const updatedUserMessagesArr = userMessagesArr.map((message) => {
          if (message._id === userMessageWithNewStatus.message._id) {
            return userMessageWithNewStatus.message;
          } else {
            return message;
          }
        });
        saveUserMessages(updatedUserMessagesArr);
        toast.success(t("Message status successfully changed!"));
      }
    } catch (error) {
      toast.error(t("unsuccessfulMessageProcessed"));
    }
  };

  useEffect(() => {
    if (isMessageReadByAdmin) {
      return;
    }
    void handleMessageReadStatusChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex", alignItem: "flex-start" }}>
      <Box sx={{ paddingRight: "24px" }}>
        <Avatar
          alt={userEmail ? userEmail.toUpperCase() : userTelegram?.toUpperCase()}
          src={`${currentUserMessage?.icon}`}
          sx={{ width: 48, height: 48 }}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: isTablet ? "auto" : "48px",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isTablet ? "column" : "row",
                gap: isTablet ? "0px" : "6px",
                marginBottom: "auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  color: "#2E263D",
                  "&::first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                {userEmail ? userEmail : userTelegram}
              </Typography>
              <Typography
                variant="caption"
                sx={{ paddingTop: "1px", fontSize: "12px", lineHeight: "20px", color: "#B4B1B9" }}
              >{`<${currentUserMessage?.userId}>`}</Typography>
            </Box>
            <Typography sx={{ fontSize: "12px", color: "#5e5e5e" }}>{formattedMessageDate}</Typography>
          </Box>
          {userTelegram && (
            <Typography
              sx={{ fontSize: "14px", lineHeight: "20px", color: "#2E263D" }}
            >{`Telegram: @${userTelegram}`}</Typography>
          )}
        </Box>
        <Box>
          <Typography sx={{ marginBottom: "40px", fontSize: "16px", lineHeight: "24px", color: "#2E263D" }}>
            {currentUserMessage?.messageText}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: isTablet ? "column" : "row",
            alignItems: "center",
            maxWidth: isTablet ? "100%" : "500px",
          }}
        >
          {userTelegram && (
            <Link
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                width: "100%",
                maxWidth: isTablet ? "100%" : "calc((100% - 50px) / 2)",
                height: "40px",
                color: "#FFFFFF",
                backgroundColor: "#3093df",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#54a9eb",
                },
                boxShadow:
                  "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
                transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
              }}
              href={`https://t.me/${userTelegram}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              {t("answerInTelegram")}
              <TelegramIcon />
            </Link>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: isTablet ? "100%" : "calc((100% - 8px) / 2)",
            }}
          >
            <Link
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "4px",
                width: "100%",
                maxWidth: "calc(100% - 50px)",
                height: "40px",
                fontWeight: 500,
                color: "#2E263D",
                backgroundColor: "#fafafa",
                borderRadius: "8px",
                "& svg": { width: "24px", height: "24px" },
                "&:hover": {
                  backgroundColor: "#F4701B",
                  color: "#FFFFFF",
                },
                boxShadow:
                  "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
                transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
              }}
              href="https://mail.google.com/mail/u/0/#inbox?compose=new"
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              {t("answerInMail")}
              <GmailIcon />
            </Link>
            <BootstrapTooltip title={t("isMessageProcessed")} placement="top">
              <Checkbox
                icon={<BookmarkBorderIcon />}
                checkedIcon={<BookmarkIcon />}
                checked={isMessageProcessedByAdmin}
                onChange={handleMessageProcessedStatusChange}
                disabled={isMessageProcessedByAdmin}
                sx={{
                  borderRadius: "8px",
                  boxShadow:
                    "rgba(255, 255, 255, 0.5) 2px 2px 2px 0px inset, rgba(0, 0, 0, 0.1) 7px 7px 20px 0px, rgba(0, 0, 0, 0.1) 4px 4px 5px 0px",
                  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
                  "&.Mui-disabled": {
                    color: "#F4701B",
                  },
                }}
              />
            </BootstrapTooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageDetails;

import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { Box } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DraftsIcon from "@mui/icons-material/Drafts";
import DeleteIcon from "@mui/icons-material/Delete";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import { useTranslation } from "react-i18next";
import useCautionStore from "store/cautionStore";

interface IActionButtonProps {
  userInfo: { id: string; email: string; telegram: string; telegramUsername: string };
}

export default function AdminActionButton({ userInfo }: IActionButtonProps) {
  const { openUserDeleteModal, handleDeleteUserChange } = useCautionStore();
  const { t } = useTranslation();

  const handleWalletChange = async (event: React.MouseEvent<HTMLDivElement>) => {
    console.log("On wallet button click");
  };

  const handleMessage = async (event: React.MouseEvent<HTMLDivElement>) => {
    console.log("On message button click");
  };

  const handleStats = async (event: React.MouseEvent<HTMLDivElement>) => {
    console.log("On Stats button click");
  };

  const handleDelete = async (event: React.MouseEvent<HTMLDivElement>) => {
    openUserDeleteModal();
    handleDeleteUserChange(userInfo);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
      <BootstrapTooltip title={t("changebalance")} placement="top">
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleWalletChange}
        >
          <CurrencyExchangeIcon sx={{ color: "#6D6777" }} />
        </Box>
      </BootstrapTooltip>

      <BootstrapTooltip title={t("messages")} placement="top">
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleMessage}
        >
          <DraftsIcon sx={{ color: "#6D6777" }} />
        </Box>
      </BootstrapTooltip>
      <BootstrapTooltip title={t("seestats")} placement="top">
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleStats}
        >
          <AssessmentOutlinedIcon sx={{ color: "#6D6777" }} />
        </Box>
      </BootstrapTooltip>
      <BootstrapTooltip title={t("deleteuser")} placement="top">
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleDelete}
        >
          <DeleteIcon sx={{ color: "#6D6777" }} />
        </Box>
      </BootstrapTooltip>
    </Box>
  );
}

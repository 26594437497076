import DraftsIcon from "@mui/icons-material/Drafts";
import { Badge } from "@mui/material";
import { useMessagesStore } from "store/userMessagesStore";

const MessagesIcon = () => {
  const { userMessagesArr } = useMessagesStore();
  const unreadMessages = userMessagesArr.filter((message) => {
    return !message.read.isRead;
  }).length;
  return (
    <Badge badgeContent={unreadMessages || 0} color="primary">
      <DraftsIcon />
    </Badge>
  );
};

export default MessagesIcon;

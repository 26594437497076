import { Grid } from "@mui/material";
import Deps from "assets/icons/Deps";
import Installs from "assets/icons/Installs";
import Opens from "assets/icons/Opens";
import Regs from "assets/icons/Regs";
import Unique from "assets/icons/Unique";
import Preloader from "components/GlobalPreloader/Preloader";
import WidgetCard from "components/WidgetCard";
import useFetch from "hooks/useFetch";
import { ITransaction } from "interfaces/stats";
import { useEffect, useState } from "react";

interface IStoreStats {
  keyword: string;
  clicks: number;
}

interface IStatisticWidget {
  usersCampaingIds: number[];
  installs: ITransaction[];
  pwaIds: string[];
}

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("authToken") || "";
  }
};

const StatisticWidget: React.FC<IStatisticWidget> = ({ pwaIds, installs, usersCampaingIds }) => {
  const token = getToken();
  const [clicks, setClicks] = useState<any>([]);
  const [storeStats, setStoreStats] = useState<Array<IStoreStats>>([]);
  const [stats, setStats] = useState({ clicks: 0, campaign_unique_clicks: 0, leads: 0, sales: 0, installs: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const { triggerFetch: getReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          timezone: "Europe/Madrid",
          interval: "7_days_ago",
        },
        metrics: ["campaign_unique_clicks", "leads", "sales"],
        filters: [
          {
            name: "campaign_id",
            operator: "IN_LIST",
            expression: usersCampaingIds,
          },
        ],
      },
    },
  });

  const { triggerFetch: getStoreReport } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/report/build",
      requestBody: {
        range: {
          timezone: "Europe/Madrid",
          interval: "7_days_ago",
        },
        dimensions: ["keyword"],
        metrics: ["clicks"],
        filters: [
          {
            name: "keyword",
            operator: "IN_LIST",
            expression: pwaIds,
          },
        ],
        sort: [
          {
            name: "clicks",
            order: "DESC",
          },
        ],
      },
    },
  });

  const buildReport = async () => {
    const res = await getReport();
    const storeReport = await getStoreReport();
    setClicks(res?.data?.rows || []);
    setStoreStats(storeReport?.data?.rows || []);
  };

  const getUserInstallsAmount = () => {
    const DateNow = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(DateNow.getDate() - 7);

    const weekInstallsAmount = installs.filter((install: ITransaction) => {
      const installDate = new Date(install.createdAt);
      return installDate >= sevenDaysAgo;
    });

    return weekInstallsAmount.length;
  };

  useEffect(() => {
    if (usersCampaingIds.length === 0) {
      return;
    }

    setIsLoading(true);
    void buildReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersCampaingIds]);

  useEffect(() => {
    const weeklyInstalls = getUserInstallsAmount();
    const weeklyClicks = storeStats.reduce((acc, item) => {
      return (acc += item.clicks);
    }, 0);
    const aggregatedStats = clicks.reduce(
      (acc: any, item: any) => {
        return {
          campaign_unique_clicks: acc.campaign_unique_clicks + (item.campaign_unique_clicks || 0),
          leads: acc.leads + (item.leads || 0),
          sales: acc.sales + (item.sales || 0),
        };
      },
      { clicks: 0, campaign_unique_clicks: 0, leads: 0, sales: 0 }
    );

    aggregatedStats.installs = weeklyInstalls;
    aggregatedStats.clicks = weeklyClicks;

    setStats(aggregatedStats);
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicks]);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          position: "relative",
          flexWrap: { xs: "nowrap", sm: "wrap" },
          width: { xs: `calc(100vw * 2.5)`, sm: "100%" },
          marginBottom: "24px",
        }}
      >
        {isLoading && (
          <Preloader
            customStyles={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              padding: "0",
              margin: "0",
              border: 0,
              background: "rgba(255, 255, 255, 0.6)",
            }}
          />
        )}
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"uniques"} value={stats.campaign_unique_clicks | 0} icon={<Unique />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"installs"} value={stats.installs | 0} icon={<Installs />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"clicks"} value={stats.clicks} icon={<Opens />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"regs"} value={stats.leads | 0} icon={<Regs />} />
        </Grid>
        <Grid item xs={7} sm={12 / 3} lg={12 / 4} xl={12 / 5}>
          <WidgetCard title={"deps"} value={stats.sales | 0} icon={<Deps />} />
        </Grid>
      </Grid>
    </>
  );
};

export default StatisticWidget;

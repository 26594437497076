import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { getToken } from "utils/getToken";
import { useMessagesStore } from "store/userMessagesStore";
import { Badge, Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EmailIcon from "@mui/icons-material/Email";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import MessagesList from "components/AdminMessagesList";
import { useEffect } from "react";
import MessageDetails from "components/AdminMessageDetails";
import CautionModal from "components/CautionModal";
import useCautionStore from "store/cautionStore";
import { toast } from "react-toastify";

const MessagesPage = () => {
  const { t } = useTranslation();
  const token = getToken();
  const { messageToDelete, isMessageDeleteModalOpen, handleMessageToDeleteChange, closeModal } = useCautionStore();
  const { userMessagesArr, messagesCategory, changeMessagesCategory, saveUserMessages, setMessageToShowId } =
    useMessagesStore();

  const unreadMessages = userMessagesArr.filter((message) => {
    return !message.read.isRead;
  });
  const unprocessedMessages = userMessagesArr.filter((message) => {
    return !message.processed.isProcessed;
  });

  //TODO - this request is now in main admin page. Need to be deleted! \\
  // const { triggerFetch: getUserMessages } = useFetch({
  //   url: "admin/messages",
  //   method: "GET",
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // });

  // const getAllUserMesagges = async () => {
  //   const { messages } = await getUserMessages();
  //   saveUserMessages(messages ? messages.reverse() : []);
  // };

  // USEEFFECT : getAllUserMesagges();
  // //TODO -------------------------------------------------------------\\

  const { loading, triggerFetch: deleteMessage } = useFetch({
    url: `admin/messages/${messageToDelete}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const handleMessagesPageChange = async (event: React.SyntheticEvent, newValue: string) => {
    setMessageToShowId("");
    changeMessagesCategory(newValue);
  };

  const handleMessageDelete = async () => {
    try {
      const deledetMessageStatus = await deleteMessage();

      if (deledetMessageStatus?.success) {
        const updatedMessagesArr = userMessagesArr.filter((message) => {
          return message._id !== messageToDelete;
        });

        saveUserMessages(updatedMessagesArr);
        handleMessageToDeleteChange(null);
        closeModal();
        toast.success(t("successfulMessageDeletion"));
      }
    } catch {
      toast.error(t("unsuccessfulMessageDeletion"));
    }
  };

  useEffect(() => {
    return () => {
      setMessageToShowId("");
      changeMessagesCategory("all");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box sx={{ marginBottom: "20px", display: "flex", gap: "10px" }}>
        <EmailIcon sx={{ width: "60px", height: "60px", color: "#6D6777" }} />
        <Typography variant="h4" sx={{ margin: "auto 0", fontSize: "24px" }}>
          {t("messagesPageTitle")}
        </Typography>
      </Box>

      <Box>
        <TabContext value={messagesCategory}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleMessagesPageChange}
              aria-label="messages category list"
              sx={{ "& .MuiTabs-indicator": { display: messagesCategory === "message-details" ? "none" : "block" } }}
            >
              <Tab
                icon={
                  <Badge badgeContent={unreadMessages.length || 0} color="primary">
                    <MailOutlineIcon />
                  </Badge>
                }
                iconPosition="start"
                label={t("allMessages")}
                value="all"
              />
              <Tab
                icon={
                  <Badge badgeContent={unreadMessages.length || 0} color="primary">
                    <MarkAsUnreadIcon />
                  </Badge>
                }
                iconPosition="start"
                label={t("unreadMessages")}
                value="unread"
              />
              <Tab
                icon={
                  <Badge badgeContent={unprocessedMessages.length || 0} color="primary">
                    <CancelScheduleSendIcon />
                  </Badge>
                }
                iconPosition="start"
                label={t("pendingMessages")}
                value="pending"
              />
              <Tab
                sx={{
                  position: "absolute",
                  width: "1px",
                  height: "1px",
                  margin: "-1px",
                  border: "0",
                  padding: "0",
                  whiteSpace: "nowrap",
                  clipPath: "inset(100%)",
                  clip: "rect(0 0 0 0)",
                  overflow: "hidden",
                }}
                value="message-details"
              />
            </TabList>
          </Box>
          <TabPanel value="all">
            <MessagesList messagesArr={userMessagesArr} />
          </TabPanel>
          <TabPanel value="unread">
            <MessagesList messagesArr={unreadMessages} />
          </TabPanel>
          <TabPanel value="pending">
            <MessagesList messagesArr={unprocessedMessages} />
          </TabPanel>
          <TabPanel value="message-details" sx={{ padding: "40px 0px 20px 20px" }}>
            <MessageDetails />
          </TabPanel>
        </TabContext>
      </Box>
      {isMessageDeleteModalOpen && <CautionModal handleConfirm={handleMessageDelete} isLoading={loading} />}
    </Box>
  );
};

export default MessagesPage;

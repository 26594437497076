import React from "react";
import { TextField, TextFieldProps, InputAdornment, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface CustomInputProps extends Omit<TextFieldProps, "variant"> {
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  customBgColor?: string;
  customHoverColor?: string;
  customFontSize?: string;
  isRequired?: boolean;
  label?: string;
  height?: string;
  multiline?: boolean;
  rows?: number;
  textTransform?: string;
  helperText?: string;
  onlyNumbersValues?: boolean;
  customWidth?: string;
  customLablePadding?: string;
  error?: boolean;
  isReadOnly?: boolean;
  minHeight?: string;
}

const StyledTextField = styled(TextField)<{
  customBgColor?: string;
  customHoverColor?: string;
  customFontSize?: string;
  customWidth?: string;
  height?: string;
  minHeight?: string;
  textTransform?: string;
}>(({ customBgColor, customFontSize, customWidth, height, textTransform, minHeight }) => ({
  "& .MuiFormControl-root": {
    width: customWidth && customWidth,
  },
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    backgroundColor: customBgColor || "inherit",
    height: height || "auto",
    minHeight: minHeight || "40px",
  },
  "& .MuiInputAdornment-root": {
    marginRight: "8px",
  },
  "& .MuiInputBase-input": {},
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  input: {
    fontFamily: "var(--cygreRegular), sans-serif",
    fontSize: customFontSize || "14px",
    lineHeight: "22px",
    border: "none",
    color: "black",
    padding: "0 14px",
    textTransform: textTransform || "none",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#000",
      fontSize: "14px",
      fontFamily: "var(--cygreRegular), sans-serif",
    },
  },
}));

const CustomInput: React.FC<CustomInputProps> = ({
  iconStart,
  iconEnd,
  customBgColor,
  customHoverColor,
  customFontSize,
  height,
  isRequired = true,
  label,
  multiline = false,
  rows,
  textTransform,
  helperText,
  onlyNumbersValues,
  customWidth,
  customLablePadding,
  error,
  isReadOnly,
  minHeight,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.key;

    const controlKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
    if (controlKeys.includes(keyCode)) {
      return;
    }

    if (!/^\d$/.test(keyCode)) {
      event.preventDefault();
    }
  };

  return (
    <>
      {label && (
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "12px",
            color: "#2E263D",
            paddingLeft: customLablePadding || "5px",
            fontFamily: "var(--cygreRegular), sans-serif",
            marginBottom: "-4px",
          }}
        >
          {label}
        </Typography>
      )}
      {/*@ts-ignore*/}
      <StyledTextField
        slotProps={{
          input: {
            startAdornment: iconStart ? <InputAdornment position="start">{iconStart}</InputAdornment> : null,
            endAdornment: iconEnd ? <InputAdornment position="end">{iconEnd}</InputAdornment> : null,
            readOnly: isReadOnly ? true : false,
          },
        }}
        customBgColor={customBgColor}
        customFontSize={customFontSize}
        customWidth={customWidth}
        height={height}
        multiline={multiline}
        rows={multiline ? rows : undefined}
        textTransform={textTransform}
        {...rest}
        helperText={helperText}
        onKeyDown={onlyNumbersValues ? handleKeyPress : undefined}
        error={error}
        minHeight={minHeight}
      />
      {!isRequired && (
        <Typography
          sx={{
            textAlign: "left",
            fontSize: "0.75em",
            color: "black",
            opacity: "0.3",
            paddingLeft: "5px",
            fontFamily: "var(--cygreBold)",
            marginTop: "-10px",
            marginLeft: "12px",
          }}
        >
          {t("notrequired")}
        </Typography>
      )}
    </>
  );
};

export default CustomInput;

import useFetch from "hooks/useFetch";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router";
import { useMessagesStore } from "store/userMessagesStore";
import { getToken } from "utils/getToken";

const AdminPage = () => {
  const { pathname } = useLocation();
  const token = getToken();
  const { saveUserMessages } = useMessagesStore();

  const { triggerFetch: getUserMessages } = useFetch({
    url: "admin/messages",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getAllUserMesagges = async () => {
    const { messages } = await getUserMessages();
    saveUserMessages(messages ? messages.reverse() : []);
  };

  useEffect(() => {
    void getAllUserMesagges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return pathname === "/admin" ? <div>Admin page in development</div> : <Outlet />;
};

export default AdminPage;

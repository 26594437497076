import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box, TablePagination, TablePaginationProps } from "@mui/material";
import Preloader from "components/GlobalPreloader/Preloader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F7FB",
    color: "#000",
    fontFamily: "var(--cygreMedium), sans-serif",
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "var(--cygreMedium), sans-serif",
    border: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  td: {
    fontSize: 14,
    fontFamily: "var(--cygreMedium), sans-serif",
    border: 0,
  },
}));

const StyledTablePagination = styled(TablePagination)<TablePaginationProps>(({ theme }) => ({
  width: "100%",
  "& .MuiTablePagination-toolbar": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
    fontFamily: "var(--cygreMedium), sans-serif",
  },
  "& .MuiTablePagination-select": {
    fontFamily: "var(--cygreMedium), sans-serif",
    paddingRight: theme.spacing(2),
  },
  "& .MuiTablePagination-actions": {
    display: "flex",
    alignItems: "center",
  },
}));

export interface IColumn {
  id: string;
  label: string;
  align?: "left" | "right" | "center";
  minWidth?: number;
  format?: (value: any) => string;
  render?: (row: any) => React.ReactNode;
}

interface ITableProps {
  columns: IColumn[];
  rows: any[];
  enablePagination?: boolean;
  onRowClick?: (row: any) => void;
  setHoveredRowId?: (value: string | null) => void;
  showLoader?: boolean;
}

export default function CustomTable({
  setHoveredRowId,
  columns,
  rows,
  enablePagination,
  onRowClick,
  showLoader,
}: ITableProps) {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const displayedRows = enablePagination ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;

  return (
    <Paper sx={{ width: "100%", boxShadow: "none", border: "none" }}>
      <TableContainer sx={{ boxShadow: "none", border: "none" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F6F7FB" }}>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {t(column.label)}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              position: "relative",
              minHeight: "200px",
            }}
          >
            {showLoader && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  zIndex: 2,
                }}
              >
                <Preloader
                  customStyles={{
                    border: 0,
                    borderRadius: 0,
                    padding: 0,
                    width: "100%",
                    height: "100%",
                    margin: 0,
                  }}
                />
              </div>
            )}

            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="left">
                  {t("noitems")}
                </TableCell>
              </TableRow>
            ) : (
              displayedRows?.map((row, rowIndex) => (
                <StyledTableRow
                  hover
                  onClick={() => onRowClick && onRowClick(row)}
                  role="checkbox"
                  tabIndex={-1}
                  key={rowIndex}
                  sx={{ cursor: "pointer", ...row.rowStyle }}
                  onMouseEnter={() => setHoveredRowId && setHoveredRowId(row._id)}
                  onMouseLeave={() => setHoveredRowId && setHoveredRowId(null)}
                >
                  {columns.map((column) => {
                    const value = row[column.id] ?? "—";
                    const cellStyle = column.id === "amount" ? { color: "#16B364" } : {};
                    const isPromo = row.isPromoBonus;
                    return (
                      <TableCell sx={cellStyle} key={column.id} align={column.align}>
                        {column.render
                          ? column.render(row)
                          : column.format && value !== "—"
                            ? column.format(value)
                            : value}
                        {isPromo && column.id === "dateofentry" && "🎁"}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {enablePagination && rows.length >= 10 && (
        <Box sx={{ display: "flex" }}>
          <StyledTablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("rowsperpage")}
          />
        </Box>
      )}
    </Paper>
  );
}
